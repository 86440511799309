export const orderTakingActionTypes = {
    ORDER_TAKING_DATA_LOAD: 'ORDER_TAKING_DATA_LOAD',
    SAVE_ORDER_TAKING: 'SAVE_ORDER_TAKING',
    PARAMETER_MST: 'PARAMETER_MST',
}

export const avrSurveyActionTypes = {
    MASTER_DATA_LOAD: 'MASTER_DATA_LOAD',
    PRINCIPAL_DATA_LOAD: 'PRINCIPAL_DATA_LOAD',
    PRODUCT_DATA_LOAD: 'PRODUCT_DATA_LOAD',
    LOCATION_DATA: "LOCATION_DATA"
}

export const callPlanningActionTypes = {
    PLAN_MONTH_DATA: 'PLAN_MONTH_DATA',
    CUSTOMER_CLASS_DATA: 'CUSTOMER_CLASS_DATA',
    CUSTOMER_TYPE_DATA: 'CUSTOMER_TYPE_DATA',
    SUMMARY_DATA: 'SUMMARY_DATA',
    REVIEW_DATA: 'REVIEW_DATA',
    UNPLAN_DATA: 'UNPLAN_DATA',
    VISIT_CUSTOMER_DATA: 'VISIT_CUSTOMER_DATA',
    OTHER_WORK_DATA: 'OTHER_WORK_DATA',
    PLAN_VISIT_DATA: 'PLAN_VISIT_DATA',
    RESET_DATA: 'RESET_DATA',
    CITY_DATA: 'CITY_DATA',
    ADDRESS_DATA: 'ADDRESS_DATA'
}

export const callPlanningManagerConstant = {
    MANAGER_DATA: 'MANAGER_DATA',
    MANAGER_REQUEST_DATA: 'MANAGER_REQUEST_DATA',
};

export const callReportActionConstant = {
    CALL_REPORT_DATA: 'CALL_REPORT_DATA',
    CALL_IMAGES_DATA: 'CALL_IMAGES_DATA',
    CALL_REPORT_MARKET_DATA: 'CALL_REPORT_MARKET_DATA',
    CALL_REPORT_TEAM_DATA: 'CALL_REPORT_TEAM_DATA',
    CALL_REPORT_SALEMAN_DATA: 'CALL_REPORT_SALEMAN_DATA',
};

export const customerVisitPlanActionTypes = {
    CUSTOMER_VISIT_PLAN: 'CUSTOMER_VISIT_PLAN',
    CUSTOMER_VISIT_PROGRESS: 'CUSTOMER_VISIT_PROGRESS',
    CUSTOMER_VISIT_PLAN_MONTH: 'CUSTOMER_VISIT_PLAN_MONTH',
};

export const flmCoachingActionTypes = {
    TIME_SHEET_HEADER: 'TIME_SHEET_HEADER',
    FLMLISTING: 'FLMLISTING',
    OBJECTIVE_DETAIL: 'OBJECTIVE_DETAIL',
    INFIELDDAY: 'INFIELDDAY',
    SET_SUBORDINATE: 'SET_SUBORDINATE',
    SET_OBJECTIVE: 'SET_OBJECTIVE',
    SET_TIMESHEET_DETAIL: 'SET_TIMESHEET_DETAIL',
    FLM_PLAN_DETAILS: 'FLM_PLAN_DETAILS',
    FLM_PLAN_DETAILS_FOR_CALENDARVIEW: 'FLM_PLAN_DETAILS_FOR_CALENDARVIEW',
    FLM_PLAN_COUNT: 'FLM_PLAN_COUNT',
    SAVE_ACKNOWLEDGEMENT_DATA: 'SAVE_ACKNOWLEDGEMENT_DATA',
    UNMOUNT_ACKNOWLEDGE: 'UNMOUNT_ACKNOWLEDGE',
    FLM_COUNT_RESET: 'FLM_COUNT_RESET',
    FLM_PLAN_COUNT_FOR_CALENDARVIEW: 'FLM_PLAN_COUNT_FOR_CALENDARVIEW',
    FLM_ACTUAL_PLAN_COUNT_FOR_CALENDARVIEW: 'FLM_ACTUAL_PLAN_COUNT_FOR_CALENDARVIEW'
}

