import React, { useState } from 'react'
import { useSelector } from 'react-redux'
import locIcon from '../../../assets/image/loc.png'
import routeIcon from '../../../assets/image/route.png'
import pinRoute from '../../../assets/image/pinRoute.png'
import { MapContainer, Marker, Popup, TileLayer, Polyline } from 'react-leaflet'
import L from 'leaflet'
import { get } from '../../../utils/request'
import { format } from 'date-fns'

var icon = L.icon({
  iconUrl: pinRoute,
  iconSize: [38, 35],
  iconAnchor: [18, 35],
  popupAnchor: [-0, -35],

})

const PopupDetailTracking = (props) => {
  const user = useSelector(state => state.auth.user)
  const [state, setState] = useState({
    isLoading: true,
    popup: false,
    srTracking: []
  })


  setTimeout(() => {
    window.dispatchEvent(new Event("resize"));
  }, 100);

  const handlePopup = () => {
    let params = {
      branchCode: props.detail.branchCode,
      slmNik: props.detail.slmNik,
      startDate: format(new Date(props.data.visitDate), 'yyyy-MM-dd'),
      endDate: format(new Date(props.data.visitDate), 'yyyy-MM-dd'),
      // tracker: state.radioTracker,
    }
    getTracking(params)
  }

  const getTracking = (params) => {
    get(
      `/api/v1/sales-tracking-report/tracking`,
      {
        headers: {
          Authorization: `Bearer ${user.token}`,
          'Content-Type': 'application/json'
        },
        params: params,
      },
      success => {
        const { data: { items } } = success.data
        setState(prevState => {
          return {
            ...prevState,
            srTracking: items,
            isLoading: false,
            popup: !state.popup
          }
        })
      },
      error => {
        console.log('error request', error)
      }
    )
  }

  const mapTracking = (data, type) => {
    if (data.length !== 0) {
      return (
        <MapContainer center={[data[0].outletLatitude, data[0].outletLongitude]} zoom={13} scrollWheelZoom={false}>
          <TileLayer
            url="https://{s}.tile.openstreetmap.org/{z}/{x}/{y}.png"
            attribution='&copy; <a href="http://osm.org/copyright">OpenStreetMap</a> contributors'
          />
          {outletMarker(data)}
          {type === 'srRoute' && routesPolyline(data)}
        </MapContainer>
      )
    } else {
      return (
        <MapContainer center={[-6.2730839, 106.7368728]} zoom={13} scrollWheelZoom={false}>
          <TileLayer
            url="https://{s}.tile.openstreetmap.org/{z}/{x}/{y}.png"
            attribution='&copy; <a href="http://osm.org/copyright">OpenStreetMap</a> contributors'
          />
        </MapContainer>
      )
    }
  }

  const outletMarker = (data) => {
    return (
      <>
        {data.map((row, idx) => {
          return (
            <Marker key={`marker-${idx}`} position={[row.outletLatitude, row.outletLongitude]} icon={icon}>
              <Popup>
                <span>{row.outletName}<br /> {row.outletAddress}.</span>
              </Popup>
            </Marker>
          )
        })}
      </>
    )
  }

  const routesPolyline = (data) => {
    const positions = []
    data.map(row => (
      row.visitRoutes.map(row2 => {
        return positions.push([row2.latitude, row2.longitude])
      })
    ))

    const lineOptions = { color: '#157478' }
    return (
      <Polyline positions={positions} pathOptions={lineOptions} />
    )
  }

  return (
    <>
      <span className="cursor-pointer" onClick={handlePopup}><img alt="icon" src={props.type === 'outletLocation' ? locIcon : routeIcon} className="flex mx-auto" /></span>
      <div className={`overflow-auto fixed w-full h-full flex flex-wrap items-center justify-center top-0 left-0 py-10 ${!state.popup ? "hidden" : ""}`} style={{ backgroundColor: "rgba(0,0,0,0.5)" }}>
        <div className="w-8/12 bg-white rounded">
          <div className="flex flex-row justify-between p-4 px-6 items-center">
            <div className="text-lg text-left">Lokasi Customer kunjungan. Tgl . {format(new Date(props.data.visitDate), 'dd/MM/yyyy')}</div>
            <div className="text-left cursor-pointer" onClick={() => setState(prevState => { return { ...prevState, popup: !state.popup } })}>Close </div>
          </div>
          <div className="flex flex-wrap max-w-screen-xl mx-auto gap-y-10 ">
            <div className="w-full mt-5 h-auto md:mt-0">
              {state.isLoading ?
                (null)
                :
                (mapTracking(state.srTracking, props.type))
              }
            </div>
          </div>
        </div>
      </div>
    </>
  )
}

export default PopupDetailTracking
