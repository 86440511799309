import { format } from "date-fns";
import { flmCoachingActionTypes } from "../constants/actionTypes";
const initialState = {
    timesheetHeader: null,
    flmCoachingListings: [],
    inFieldDay: format(new Date(), ('yyyy-MM-dd')),
    objectivePageDetail: [],
    objectivePageMst: [],
    timeSheetDetails: [],
    flmAllDates: []
}

const planInitialState = {

}

export const FLMCoachingReducer = (state = initialState, { type, payload = {} }) => {
    switch (type) {
        case flmCoachingActionTypes.TIME_SHEET_HEADER: {
            return {
                ...state,
                timesheetHeader: payload.data.success ? { ...payload.data.data[0] } : null
            }
        }
        case flmCoachingActionTypes.FLMLISTING: {
            return {
                ...state,
                flmCoachingListings: payload.data.success ? [...payload.data.data] : null
            }
        }
        case flmCoachingActionTypes.OBJECTIVE_DETAIL: {
            return {
                ...state,
                objectivePageDetail: payload.data.success ? [...payload.data.objectivePageDetail] : null,
                objectivePageMst: payload.data.success ? [...payload.data.objectivePageMst] : null,
            }
        }
        case flmCoachingActionTypes.INFIELDDAY: {
            return {
                ...state,
                inFieldDay: payload
            }
        }
        case flmCoachingActionTypes.SET_SUBORDINATE: {
            return {
                ...state,
                selectedSubordinate: payload
            }
        }
        case flmCoachingActionTypes.SET_OBJECTIVE: {
            return {
                ...state,
                selectedObjective: payload
            }
        }
        case flmCoachingActionTypes.SET_TIMESHEET_DETAIL: {
            return {
                ...state,
                timeSheetDetails: payload
            }
        }
        case flmCoachingActionTypes.SAVE_ACKNOWLEDGEMENT_DATA: {
            return {
                ...state,
                fromAcknowledgementPage: true,
                timesheetHeader: {
                    timeSheetHdrNo: payload.timeSheetHdrNo,
                    flmName: payload.flmName,
                    isFinalSave: true
                },
                timeSheetDetails: {
                    timeSheetDetailNo: payload.timeSheetDetailNo,
                    flmNik: payload.flmNik,
                    memberNik: payload.memberNik,
                    memberFullName: payload.memberFullName,

                },
                acknowledgeFLMData: payload
            }
        }
        case flmCoachingActionTypes.UNMOUNT_ACKNOWLEDGE: {
            return {
                ...state,
                fromAcknowledgementPage: false
            }
        }
        case 'GET_ALL_DATES_LIST': {
            return {
                ...state,
                flmAllDates: payload
            }
        }
        case 'GET_SELECTED_POSITION_MEMBER': {
            return {
                ...state,
                selectedPositionMember: payload
            }
        }
        default: return state
    }
}

export const FLMPlanReducer = (state = planInitialState, { type, payload = {} }) => {
    switch (type) {
        case flmCoachingActionTypes.FLM_PLAN_DETAILS: {
            return {
                ...state,
                flmPlanDetails: payload
            }
        }
        case flmCoachingActionTypes.FLM_PLAN_DETAILS_FOR_CALENDARVIEW: {
            return {
                ...state,
                salesRepflmPlanDetails: payload
            }
        }
        case flmCoachingActionTypes.FLM_ACTUAL_PLAN_COUNT_FOR_CALENDARVIEW: {
            return {
                ...state,
                salesRepflmActualPlanDetails: payload
            }
        }
        case flmCoachingActionTypes.FLM_PLAN_COUNT: {
            return {
                ...state,
                flmCount: payload
            }
        }

        case flmCoachingActionTypes.FLM_PLAN_COUNT_FOR_CALENDARVIEW: {
            return {
                ...state,
                flmCountForCalendar: payload
            }
        }

        case flmCoachingActionTypes.FLM_COUNT_RESET: {
            return {
                ...state,
                flmCount: null,
            }
        }

        case "RESET_LEAVES": {
            return {
                ...state,
                salesRepflmActualPlanDetails: null,
                salesRepflmPlanDetails: null
            }
        }
        default: return state
    }
}