
const initialState = {
  user: null,
  profileData: null,
  visitId: null,
  date: null,
  outletCode: null
}

export const AuthReducer = (state = initialState, { type, payload = {} }) => {
  switch (type) {
    case 'SIGN_IN': {
      return {
        ...state,
        user: {
          ...payload.data
        }
      }
    }
    case 'SIGNOUT': {
      return {
        ...state,
        user: null
      }
    }
    case 'GET_PROFILE_LIST': {
      return {
        ...state,
        profileData: {
          ...payload
        }
      }
    }
    case 'GET_VISIT_ID': {
      return {
        ...state,
        visitId: payload.visitId
      }
    }
    case 'GET_VISIT_DETAILED_DATE': {
      return {
        ...state,
        date: payload.date,
        outletCode: payload.outletCode
      }
    }
    case 'POSITION_CHANGE': {
      return {
        ...state,
        updatePositionDetail: payload,
      }
    }
    case 'POSITION_DATA': {
      return {
        ...state,
        positionDetail: payload,
      }
    }
    case 'POSITION_DATA_ALL': {
      return {
        ...state,
        positionAllDetail: payload,
      }
    }
    case 'POSITION_DATA_CHANGE': {
      return {
        ...state,
        positionDataChange: payload,
      }
    }
    case 'POSITION_DATA_CHANGE_CALL_PLANNING': {
      return {
        ...state,
        positionDataChangeCallPlanning: payload,
      }
    }
    case 'POSITION_HIERARCHY_DATA': {
      return {
        ...state,
       positionhierarchydata: payload,
      }
    }
    case 'RESET': {
      return {
        ...state,
        updatePositionDetail: null,
        positionDetail: null,
        positionAllDetail: null,
        positionDetailChange: null,
      }
    }
    default: return state
  }
}