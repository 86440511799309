import axios from "axios";
import { instance } from "../../utils/http-common";

const domain_power_pi = process.env.REACT_APP_BASE_URL_POWER_PI;
const pbi_Env = process.env.REACT_APP_PBI_ENV;
const domain = process.env.REACT_APP_BASE_URL;
const limit = 1000000;
const page = 1;

const handleResponse = res => res.data;
const handleError = error => error.response?.data;

const request = {
    get: (url, body) => instance.get(`${domain}${url}`, body).then(handleResponse).catch(handleError),
    post: (url, body) => instance.post(`${domain}${url}`, body).then(handleResponse).catch(handleError),
    put: (url, body) => instance.put(`${domain}${url}`, body).then(handleResponse).catch(handleError),
    del: url => instance.delete(`${domain}${url}`).then(handleResponse).catch(handleError),
}

const customerOutletCoverageAPI = {
    getCustomerOutletCoverage: (date, memberRayonCode) =>
        request.get(`/api/v1/outlet/coverage?limit=${limit}&page=${page}&Date=${date}&rayonCode=${memberRayonCode}`),
    updateCustomerOutletCoverage: (outletId, payload) =>
        request.put(`/api/v1/outlet/update/${outletId}`, payload),
}

const customerSurveyAPI = {
    getCustomerSurveys: (visitId) =>
        request.get(`/api/v1/visit/temp/surveys/${visitId}`),
}

const customerVisitPlanAPI = {
    getCustomerVisitPlan: (params) =>
        request.get('/api/v1/visit/fetch', { params: params }),
    getVisitProgress: (startDate, endDate, id) =>
        request.get(`/api/v1/visit/progress?startDate=${startDate}&endDate=${endDate}&MemberPositionId=${id}`),
    createCustomerVisit: (payload) =>
        request.post('/api/v1/visit/store', payload),
    getVisitOtherwork: (params) =>
        request.get('/api/v1/visit/otherwork/fetch', { params: params }),
    confirmYes: (params) =>
        request.put(`/api/v1/visit/otherwork/confirmed/${params}`),
    confirmNo: (params) =>
        request.put(`/api/v1/visit/otherwork/canceled/${params}`),
    sendUnPlanWork: (payload) =>
        request.post('/api/v1/visit/otherwork', payload),
    getTelesalesmastersById: (visitId) =>
        request.get(`/api/v1/teleSales/getTelesalesmastersById?id=${visitId}`),
}

const memberPriviledgeAPI = {
    getMemberPriviledgeMenu: (params) =>
        request.get(`/api/v1/member/priviledge_menus?memberSalesTypeId=${params.memberSalesTypeId}&memberTypeId=${params.memberTypeId}`),
}
// const memberPriviledgeAPI = {
//     getMemberPriviledgeMenu: () =>
//         request.get('/api/v1/member/priviledge_menus'),
// }
const merchandiseAPI = {
    getMerchandise: (visitId) =>
        request.get('/api/v1/merchandise/fetch', { params: { visitId: visitId } }),
}

const visitPlanAPI = {
    getPublicHolidays: (date) =>
        request.get('/api/v1/public-holiday/fetch', { params: date }),
    getWeeklyOffs: (date) =>
        request.get('/api/v1/weekly-off/fetch', { params: date }),
    getVisitPlanByIds: (visitId) =>
        request.get(`/api/v1/visit/detail/${visitId}`),
}

const avrSurveyAPI = {
    fetch: () =>
        request.get(`/api/v1/dropdown/visit/avr/fetch`),
    getPrincipals: (visitId) =>
        request.get(`/api/v1/visit/principal/${visitId}`),
    getPrincipalsNew: (date, salesOrg, outletCode, visitId, memberPositionId) =>
        request.get(`/api/v1/product-focus/customer-stock-recordv1?Date=${date}&salesOrg=${salesOrg}&outlateCode=${outletCode}&visitId=${visitId}&memberpositionId=${memberPositionId}`),
    getProducts: (visitId) =>
        request.get(`/api/v1/visit/product/stock-inventory-quantity/${visitId}`),
    getAvailability: (visitId) =>
        request.get(`/api/v1/visit/avr/availability/${visitId}`),
    getVisibility: (visitId) =>
        request.get(`/api/v1/visit/avr/visibility/${visitId}`),
    getRecommendation: (visitId) =>
        request.get(`/api/v1/visit/avr/recommendation/${visitId}`),
    saveAvailability: (visitId, body) =>
        request.post(`/api/v1/visit/avr/availability/${visitId}`, body),
    saveVisibility: (visitId, body) =>
        request.post(`/api/v1/visit/avr/visibility/${visitId}`, body),
    saveRecommendation: (visitId, body) =>
        request.post(`/api/v1/visit/avr/recommendation/${visitId}`, body),
}

const customerCollectionAPI = {
    getDih: (visitId) =>
        request.get(`/api/v1/collection/dih?visitId=${visitId}`),
    getReceipt: (visitId) =>
        request.get(`/api/v1/visit/receipt/collection/${visitId}`),
    getVisitCollection: (visitId) =>
        request.get(`/api/v1/visit/collection/${visitId}`),
}

const presentationAPI = {
    getProductFocus: (params) =>
        request.get(`/api/v1/product-focus/productFocusList`, { params: params }),
    getPresentationDropdown: (params) =>
        request.get(`/api/v1/teleSales/getTelesalesmasters?Type=${params.Type}`),
    getNewProduct: (params) =>
        request.get(`/api/v1/new-product/fetch`, { params: params }),
    getReason: (params) =>
        request.get(`/api/v1/reason-nopay`, { params: params }),
    getVisitDetail: (visitId) =>
        request.get(`/api/v1/visit/detail/${visitId}`),
    getProductAdditional: (visitId) =>
        request.get(`/api/v2/product_additional/${visitId}`),
    getAdditionalOrder: (visitId) =>
        request.get(`/api/v2/visit-product-additional?visitId=${visitId}`),
    savePresentation: (visitId, body) =>
        request.post(`/api/v2/visit-selling-and-detailing/store/${visitId}`, body),
    saveAdditional: (visitId, body) =>
        request.post(`/api/v1/visit-product-additional/store?visitId=${visitId}`, body),
}

const callSummaryAPI = {
    getOptions: () =>
        request.get(`/api/v1/dropdown/fetch?dropdown_type=call_summary_barrier_encountered`),
    getVisitSumary: (visitId) =>
        request.get(`/api/v1/visit/summary/${visitId}`),
    getVisitDetail: (visitId) =>
        request.get(`/api/v1/visit/detail/${visitId}`),
    getServicesDetail: (visitId) =>
        request.get(`/api/v1/visit/services/${visitId}`),
    saveEndCall: (body) =>
        request.post(`/api/v1/visit/endcall`, body),
    saveCheckAcurrate: (body) =>
        request.post(`/api/v1/visit/check-accurate`, body),
    pause: (body) =>
        request.put(`/api/v1/visit/update-status`, body)
}

const orderTakingAPI = {
    get: (visitId) =>
        request.get(`/api/v1/visit/survey/order-taking/${visitId}`),
    save: (body) =>
        request.post(`/api/v1/visit/survey/order-taking`, body),
    getParameterMstList: () =>
        request.get(`/api/v1/member/getParameterMstList`),
}
const callplanningAPI = {
    getCallPlanningOfMonth: (params) =>
        request.get(`/api/v1/callplanning/month`, { params: params }),
    getVisitCustomers: (params) =>
        request.get(`/api/v1/callplanning/customers?limit=${limit}&page=${page}`, { params: params }),
    getCallPlanningOfMonthBy: (params, params2) =>
        request.get(`/api/v1/callplanning/${params}`, { params: params2 }),
    getPlanVisitDetails: (params) =>
        request.get(`/api/v1/callplanning/manager/month`, { params: params }),
    getOtherWorks: (params) =>
        request.get(`/api/v1/callplanning/otherworks?limit=${limit}&page=${page}`),
    getManagerSummaryData: (params) =>
        request.get(`/api/v1/callplanning/manager/planned`, { params: params }),
    getManagerReviewData: (params) =>
        request.get(`/api/v1/callplanning/manager/review/planned`, { params: params }),
    getManagerUnPlanData: (params) =>
        request.get(`/api/v1/callplanning/manager/review/unplan`, { params: params }),

    getSummaryData: (params) =>
        request.get(`/api/v1/callplanning/planned`, { params: params }),
    getReviewData: (params) =>
        request.get(`/api/v1/callplanning/review/planned`, { params: params }),
    getUnPlanData: (params) =>
        request.get(`/api/v1/callplanning/review/unplan`, { params: params }),
    saveAndSaveAs: (paramsSummary, body) =>
        request.post(`/api/v1/callplanning/v2?memberSalesTypeCode=${paramsSummary.memberSalesTypeCode}&rayonTypeCode=${paramsSummary.rayonTypeCode}&rayonCode=${paramsSummary.rayonCode}`, body),
    saveAndSaveAsMulti: (body, params) =>
        request.post(`/api/v1/callplanning/multi?month=${params.month}&year=${params.year}&memberSalesTypeCode=${params.memberSalesTypeCode}&rayonTypeCode=${params.rayonTypeCode}&rayonCode=${params.rayonCode}`, body),
    sendForApprove: (params) =>
        request.put(`/api/v1/callplanning/request/${params}`),
    getCallPlanCity: (params) =>
        request.get(`/api/v1/callplanning/customers/city?rayonCode=${params}`),
    getCallPlanAddress: (params) =>
        request.get(`/api/v1/callplanning/customers/address?rayonCode=${params.rayonCode}&city=${params.city !== undefined ? params.city : ''}`),
}

const callPlanningApproveAPI = {
    fetchManager: (params) =>
        request.get(`/api/v1/callplanning/manager`, { params: params }),
    fetchManagerRequest: (params) =>
        request.get(`/api/v1/callplanning/manager/request`, { params: params }),
    sendReviewing: (params) =>
        request.put(`/api/v1/callplanning/reviewing/${params}`),
    approved: (body, params) =>
        request.put(`/api/v1/callplanning/approved?FromDate=${params.FromDate}&ToDate=${params.ToDate}`, body),
    rejected: (body) =>
        request.put(`/api/v1/callplanning/rejected`, body),
    rejectedById: (params, body) =>
        request.put(`/api/v1/callplanning/rejected/${params}`, body),
    callplaningstatusApproval: (params) =>
        request.get(`/api/v1/callplanning/manager/statusApproval`, params = { params: params }),
}
const coachingPlanningApproveAPI = {
    fetchManager: (params) =>
        request.get(`/api/v1/callplanning/manager`, { params: params }),
    fetchManagerRequest: (params) =>
        request.get(`/api/v1/callplanning/manager/request`, { params: params }),
    sendReviewing: (params) =>
        request.get(`/api/v1/flm/timesheetForApproveAndStatusDataApprove?month=${params.month}&year=${params.year}&flmNik[]=${params.flmNik}&status=${params.type}&memberpositionId[]=${params.memberpositionId}`),
    timesheetplaningdetails: (params) =>
        request.get(`/api/v1/flm/timesheetPlanningDetail?planMonth=${params.month + 1}&planYear=${params.year}&flmMemberNik=${params.flmNik}`),
    approved: (params) =>
        request.get(`/api/v1/flm/timesheetForApproveAndStatusDataApprove?month=${params.month}&year=${params.year}&flmNik[]=${params.flmNik}&status=${params.type}&memberpositionId[]=${params.memberpositionId}`),
    rejected: (params) =>
        request.get(`/api/v1/flm/timesheetForApproveAndStatusDataApprove?month=${params.month}&year=${params.year}&flmNik[]=${params.flmNik}&status=${params.status}&memberpositionId[]=${params.memberpositionId}`),
    rejectedById: (params, body) =>
        request.put(`/api/v1/callplanning/rejected/${params}`, body),
    coachingplaningstatusApproval: (params) =>
        request.get(`/api/v1/flm/timesheetForApproveAndStatus`, params = { params: params }),
}
const callReportAPI = {
    fetchReportDetail: (params) =>
        request.get(`/api/v1/visit/report/call-detail-report`, { params: params }),
    fetchImages: (params) =>
        request.get(`/api/v1/visit/report/call-report/photos/${params}`),
    fetchMarket: (params) =>
        request.get(`/api/v1/new-user-position-matrix/SalesOrg?`, { params: params }),
    fetchTeam: (params) =>
        request.get(`/api/v1/new-user-position-matrix/TeamList?`, { params: params }),
    fetchTeamNew: (params) =>
        request.get(`/api/v1/team-member/teamListByEmail`, { params: params }),
    fetchSalesRepNew: (params) =>
        request.get(`/api/v1/team-member/teamListByEmail`, { params: params }),
    fetchPositionNew: (params) =>
        request.get(`/api/v1/team-member/teamListByEmail`, { params: params }),
    fetchOriginalTeamNew: (params) =>
        request.get(`/api/v1/team-member/teamListByEmail`, { params: params }),
    fetchSaleman: (params) =>
        request.get(`/api/v1/new-user-position-matrix/MemberList?`, { params: params }),
    fetPositionListUpdate: (params) =>                          // changes by hardik
        request.get(`/api/v1/new-user-position-matrix/Position?`, { params: params }),
    fetchfLM: (member_nik) =>
        request.get(`/api/v1/Teamsmember/${member_nik}`),
}

const TeleSellerAPI = {
    fetchTeam: (params) =>
        request.get(`/api/v1/team-member/teamlistForteleSalers`, { params: params }),
    fetchSaleman: (params) =>
        request.get(`/api/v1/team-member/teammemberForteleSalers`, { params: params }),
}

const accountAPI = {
    getProfileUser: (params) =>
        request.get(`/api/v1/team-member/hirarki-profile/${params}`),
}

const downloadAPI = {
    sendVersion: (body) =>
        request.post(`/api/v1/MembersDownlodAppLog`, body),
}

const powerBIAPI = {
    get: () => {
        return axios.get(`${domain_power_pi}/getPBIEmbedToken?powerbienv=${pbi_Env}`, {
            headers: {
                'Ocp-Apim-Subscription-Key': 'cba5e7283cda47449584a17c021b19b8',
                'Content-Type': 'application/json'
            },
        })
    },
    export: () => {
        return axios.get(`${domain_power_pi}/pbiexport/ezengage/r1/PDF`, {
            headers: {
                'Ocp-Apim-Subscription-Key': 'cba5e7283cda47449584a17c021b19b8',
                'Content-Type': 'application/json'
            },
        })
    }
}

const powerBIAPIForDashboardMEnu = {
    get: (reportId, workspaceId) => {
        return axios.get(`${domain_power_pi}/getPBIEmbedToken?powerbienv=${pbi_Env}&REPORT_ID=${reportId}&WORKSPACE_ID=${workspaceId}`, {
            headers: {
                'Ocp-Apim-Subscription-Key': 'cba5e7283cda47449584a17c021b19b8',
                'Content-Type': 'application/json'
            },
        })
    },
    export: () => {
        return axios.get(`${domain_power_pi}/pbiexport/ezengage/r1/PDF`, {
            headers: {
                'Ocp-Apim-Subscription-Key': 'cba5e7283cda47449584a17c021b19b8',
                'Content-Type': 'application/json'
            },
        })
    }
}

const FLMAPI = {
    getHeader: (params) =>
        request.get(`/api/v1/flm/timesheethdr?startDate=${params.startDate}&memberpositionId=${params.memberpositionId}&memberTypeCode=${params.memberTypeCode}`),
    getTeamMember: (params, para) =>
        request.get(`/api/v1/Teamsmember/${params}?memberTypeCode=${para.memberTypeCode}&membersalestypeId=${para.membersalestypeId}&Date=${para.Date}`),
    getTeamMemberBySalesID: (params) =>
        request.get(`/api/v1/teamWiseFlmAnsSaleRepListv1?${params}`),
    getUserMetrix: (params) =>
        request.get(`/api/v1/new-user-position-matrix/MemberList?type=${params.type}&sale_org=${params.salesOrg}&team_code[]=${params.teamcode}&date=${params.date}`),
    getObjectiveDetail: (params) =>
        request.get(`/api/v1/flm/pageobjectivedetail?memberTypeCode=${params.memberTypeCode}&saleOrg=${params.saleOrg}`),
    getObjectiveDetailSLM: (params) =>
        request.get(`/api/v1/flm/pageobjectivedetail?memberTypeCode=${params.memberTypeCode}&saleOrg=${params.saleOrg}`),
    getObjectiveData: (params) =>
        request.get(`/api/v1/flm/objectivedata?memberTypeCode=${params}`),
    getFLMCoachings: (params) =>
        request.get(`/api/v1/flm/timesheetdetails?&memberTypeCode=${params.memberTypeCode}&timeSheetHdrNo=${params.timeSheetHdrNo}&memberpositionId=${params.memberpositionId}`),
    getRatings: (params) =>
        request.get(`/api/v1/flm/timesheetratingByHeaderNum?timeSheetHdrNo=${params.timeSheetHdrNo}&timeSheetDtlNo=${params.timeSheetDtlNo}&memberNik=${params.memberNik}&flmNik=${params.flmNik}`),
    postFLMTimeSheet: (body) =>
        request.post(`/api/v1/flm/timesheetdetails`, body),
    saveForm: (params, body) =>
        request.post(`/api/v1/flm/timesheetratingV2?saleOrg=${params.saleOrg}&memberTypeCode=${params.memberTypeCode}&memberpositionId=${params.memberpositionId}`, body),
    finalSave: (params) =>
        request.get(`/api/v1/flm/timesheetFinalSave?timeSheetHdrNo=${params.timeSheetHdrNo}&isFinalSave=1&submitDate=${params.submitDate}&comments=${params.comments}&teamid=${params.teamId}&teamcode=${params.teamcode}&teamname=${params.teamname}&platform=${params.platform}&memberpositionId=${params.memberpositionId}`),
    getFLMPlanningDetails: (params) =>
        request.get(`/api/v1/flm/timesheetPlanningDetail?planMonth=${params.month + 1}&planYear=${params.year}&memberpositionId=${params.selectedPositionId}`),
    getFLMPlanningDetailsForCalendarView: (params) =>
        request.get(`/api/v1/flm/timesheetPlanningDetail?planMonth=${params.month + 1}&planYear=${params.year}&flmMemberNik=${params?.flmMemberNik}&memberpositionId=${params.memberPositionId}`),
    getFLMnikPlanDetails: (params) =>
        request.get(`/api/v1/flm/timesheetPlanningDetail?planMonth=${params.month + 1}&planYear=${params.year}&flmMemberNik=${params.flmnik}&memberpositionId=${params.MemberPositionId}`),
    savePlanData: (params, body) =>
        request.post(`/api/v1/flm/timesheetPlanningDetail?memberTypeCode=${params}`, body),
    deleteFLMPlan: (params) =>
        request.del(`/api/v1/flm/timesheetPlanningDetail?timesheetPlanHdrNo=${params.timeSheetPlanningHdrNo}&timesheetPlanDtlNo=${params.timeSheetPlanningDtlNo}`),
    finalSavePlanned: (params) =>
        request.get(`/api/v1/flm/timesheetPlanningFinalSave?planMonth=${params.month}&planYear=${params.year}&planningStatus=Waiting&teamid=${params.teamid}&teamcode=${params.teamcode}&teamname=${params.teamname}&memberpositionId=${params.memberpositionId}&saleOrg=${params.saleOrg}`),
    finalAsmSavePlanned: (params) =>
        request.get(`/api/v1/flm/timesheetPlanningFinalSave?planMonth=${params.month}&planYear=${params.year}&planningStatus=Approved&teamid=${params.teamid}&teamcode=${params.teamcode}&teamname=${params.teamname}&memberpositionId=${params.memberpositionId}&saleOrg=${params.saleOrg}`),
    getFLMPlanCount: (params) =>
        request.get(`/api/v1/flm/timesheetPlanWorkCount?planMonth=${params.month + 1}&planYear=${params.year}&memberpositionId=${params.selectedPositionId}`),
    getFLMPlanCountForCalendarView: (params) =>
        request.get(`/api/v1/flm/timesheetPlanWorkCount?planMonth=${params.month + 1}&planYear=${params.year}&flmMemberNik=${params?.flmMemberNik}`),
    getFLMnikPlanCount: (params) =>
        request.get(`/api/v1/flm/timesheetPlanWorkCount?planMonth=${params.month + 1}&planYear=${params.year}&flmMemberNik=${params.flmnik}&memberpositionId=${params.MemberPositionId}`),
    getFLMAcknowledgementFLM: (params) =>
        request.get(`/api/v1/flm/timesheetAcknowledgement?planMonth=${params.month}&planYear=${params.year}&flmNik=${params.flmNik !== undefined ? params.flmNik : ""}&memberNik=${params.membernik}`),
    getFLMAcknowledgementMember: (params) =>
        request.get(`/api/v1/flm/timesheetAcknowledgement?planMonth=${params.month}&planYear=${params.year}&memberNik=${params.flmNik}`),
    getSLMAcknowledgementSLM: (params) =>
        request.get(`/api/v1/flm/timesheetAcknowledgement?planMonth=${params.month}&planYear=${params.year}&slmnik=${params.flmNik}`),
    updateAcknowledgementStatus: (params) =>
        request.get(`/api/v1/flm/timesheetAcknowledgementUpdate?timeSheetHdrNo=${params.timesheetHeader}&ackStatus=${params.ackStatus}&ackRemark=${params.ackRemark}`),
    getFLMAcknowledgement: (params) =>
        request.get(`/api/v1/flm/timesheetAcknowledgement?planMonth=${params.month}&planYear=${params.year}`),
    getFLMTimeSheetCount: (params) =>
        request.get(`/api/v1/flm/timesheetAndPlanningDetail?planMonth=${params.month + 1}&planYear=${params.year}&memberpositionId=${params.memberPositionId}`),
    getFLMTimeSheetCountForCalendarView: (params) =>
        request.get(`/api/v1/flm/timesheetAndPlanningDetail?planMonth=${params.month + 1}&planYear=${params.year}&flmMemberNik=${params?.flmMemberNik}&memberpositionId=${params.memberPositionId}`),
    deleteMonthlyFLMPlan: (params) =>
        request.del(`/api/v1/flm/timesheetPlanningDetailByMonth?timesheetPlanFlmNik=${params.timesheetPlanFlmNik}&timesheetPlanYear=${params.timesheetPlanYear}&timesheetPlanMonth=${params.timesheetPlanMonth}`),

}

export {
    accountAPI,
    powerBIAPI,
    powerBIAPIForDashboardMEnu,
    avrSurveyAPI,
    visitPlanAPI,
    callReportAPI,
    TeleSellerAPI,
    orderTakingAPI,
    callSummaryAPI,
    merchandiseAPI,
    presentationAPI,
    callplanningAPI,
    customerSurveyAPI,
    memberPriviledgeAPI,
    customerVisitPlanAPI,
    customerCollectionAPI,
    callPlanningApproveAPI,
    coachingPlanningApproveAPI,
    customerOutletCoverageAPI,
    downloadAPI,
    FLMAPI,
}
